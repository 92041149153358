<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  components: { MasterDetail },
  data() {
    return {
      opts: {
        perfis: []
      }
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "perfil_id",
          name: "Senioridade",
          type: this.$fieldTypes.SELECT,
          rel: { to: "perfis", key: "id", name: "name" },
          colSize: 12
        },
        {
          key: "custo_medio",
          name: "Custo médio",
          type: this.$fieldTypes.MONEY,
          colSize: 12
        },
        {
          key: "competencia_inicio",
          name: "Competência inicial",
          type: this.$fieldTypes.MONTH,
          colSize: 6
        },
        {
          key: "competencia_fim",
          name: "Competência final",
          type: this.$fieldTypes.MONTH,
          colSize: 6
        }
      ];
    },
    resourceUrl() {
      return `v1/ativacaoped/${this.clientId}/custo-medio`;
    }
  },
  async created() {
    await this.getPerfis();
  },
  methods: {
    doImport() {
      window.alert("TO DO Implementar");
    },
    async getPerfis() {
      try {
        const { get } = this.apiResource(
          `v1/ativacaoped/${this.clientId}/perfil/selecao`
        );
        const response = await get();
        this.opts.perfis = response
          .map(({ especialidade, senioridade, ...rest }) => ({
            especialidade,
            senioridade,
            name: `${especialidade ?? "Sem especialidade"} (${senioridade ??
              "Sem senioridade"})`,
            ...rest
          }))
          .sort(({ name: a }, { name: b }) => a.localCompare(b));
      } catch (error) {
        this.notify(error);
        this.opts.perfis = [];
      }
    }
  }
};
</script>

<template>
  <MasterDetail
    :cols="cols"
    :hasFilter="false"
    :hasExportCSV="false"
    newButtonText="Adicionar custo"
    :opts="opts"
    :resourceUrl="resourceUrl"
    serverPagination
    @onOpenFormDialog="getPerfis()"
  >
    <div class="d-flex">
      <v-spacer></v-spacer>
      <slot name="actionButtons"></slot>
      <div
        aria-label="importButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doImport()"
      >
        <v-icon>mdi-upload-box</v-icon> Importações
      </div>
    </div>
  </MasterDetail>
</template>
