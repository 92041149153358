<script>
import CustoMedio from "./views/customedio.vue";
import Listagem from "./views/perfis.vue";

export default {
  components: { CustoMedio, Listagem },
  data() {
    return {
      selectedWindow: 0,
    };
  },
  methods: {
    swapView() {
      this.selectedWindow = this.selectedWindow < 1 ? this.selectedWindow + 1 : 0;
    }
  }
};
</script>

<template>
  <v-window v-model="selectedWindow" reverse>
    <v-window-item>
      <Listagem>
        <template v-slot:actionButtons>
          <div
            aria-label="swapButton"
            class="table-v-action-button mr-3 pt-1"
            @click="swapView()"
          >
            <v-icon>mdi-swap-horizontal</v-icon> Custo Médio
          </div>
        </template>
      </Listagem>
    </v-window-item>
    <v-window-item>
      <CustoMedio>
        <template v-slot:actionButtons>
          <div
            aria-label="swapButton"
            class="table-v-action-button mr-3 pt-1"
            @click="swapView()"
          >
            <v-icon>mdi-swap-horizontal</v-icon> Custo Médio
          </div>
        </template>
      </CustoMedio>
    </v-window-item>
  </v-window>
  <!-- <MasterDetail
    :cols="cols"
    :hasFilter="false"
    :hasExportCSV="false"
    :resourceUrl="resourceUrl"
    serverPagination
  >
    <div class="d-flex">
      <v-spacer></v-spacer>
      <div
        aria-label="importButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doImport()"
      >
        <v-icon>mdi-upload-box</v-icon> Importações
      </div>
    </div>
  </MasterDetail> -->
</template>
